import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
					<path d="M6006 11320 c-913 -81 -1806 -422 -2520 -963 -396 -299 -782 -699
-1063 -1102 -415 -594 -694 -1281 -812 -2000 -50 -302 -56 -383 -56 -780 0
-413 7 -502 65 -844 134 -779 482 -1558 976 -2177 146 -183 189 -231 383 -424
396 -395 789 -677 1281 -920 510 -253 1006 -399 1615 -477 203 -26 877 -26
1080 0 506 65 896 167 1335 349 1381 574 2428 1769 2820 3217 282 1043 207
2168 -209 3149 -257 605 -594 1105 -1051 1562 -784 782 -1748 1248 -2890 1396
-145 19 -791 28 -954 14z m168 -632 c7 -32 54 -467 66 -612 6 -66 12 -122 15
-124 2 -2 6 11 9 29 8 47 74 412 97 539 11 58 23 123 27 145 l7 40 70 3 c39 1
75 -1 81 -5 13 -8 85 -442 106 -630 6 -62 14 -113 18 -113 3 0 25 158 49 350
23 193 45 360 48 370 5 18 14 20 100 20 l96 0 -7 -32 c-6 -30 -69 -368 -147
-793 -16 -88 -32 -168 -35 -177 -5 -16 -19 -18 -104 -18 -95 0 -100 1 -105 23
-22 101 -95 576 -95 624 0 40 -11 -3 -31 -113 -19 -111 -94 -478 -105 -516 -5
-16 -19 -18 -102 -18 l-97 0 -44 268 c-24 147 -56 348 -72 447 -16 99 -34 204
-39 234 -16 86 -22 81 90 81 95 0 100 -1 104 -22z m1503 -161 c152 -78 190
-275 108 -563 -63 -220 -178 -383 -295 -418 -64 -19 -95 -20 -158 -4 -169 42
-244 203 -208 447 42 282 169 493 334 557 56 21 156 12 219 -19z m-2421 -175
c50 -86 128 -222 174 -302 165 -289 233 -405 243 -418 14 -18 3 -25 -78 -52
-38 -12 -75 -25 -81 -27 -6 -2 -37 41 -68 96 -32 56 -62 101 -69 101 -7 0 -47
-12 -89 -26 -56 -18 -78 -30 -78 -42 0 -9 7 -62 15 -117 7 -55 11 -103 7 -106
-10 -9 -165 -59 -169 -55 -6 6 -113 911 -116 978 l-2 61 95 33 c52 18 102 33
110 33 9 1 50 -60 106 -157z m3127 -121 c247 -119 323 -253 241 -426 -43 -93
-120 -155 -209 -170 -66 -11 -66 -11 -50 -42 27 -50 18 -128 -30 -283 -25 -80
-45 -152 -45 -162 0 -9 -4 -20 -10 -23 -10 -6 -160 71 -171 88 -8 11 1 46 52
197 47 140 50 191 12 226 -15 14 -33 23 -41 22 -8 -2 -57 -81 -109 -176 l-95
-172 -80 42 c-44 24 -82 48 -85 54 -2 6 14 42 35 80 22 38 89 159 149 269 60
110 151 275 202 367 l92 167 40 -14 c22 -8 68 -28 102 -44z m-3770 -23 c21
-37 36 -72 32 -78 -3 -5 -35 -26 -70 -46 -36 -19 -67 -39 -69 -42 -2 -4 17
-43 42 -87 25 -44 61 -109 82 -145 20 -36 91 -160 158 -276 67 -116 122 -215
122 -221 0 -9 -143 -93 -159 -93 -5 0 -68 102 -139 228 -72 125 -165 287 -208
359 l-77 133 -78 -42 c-43 -24 -80 -40 -82 -38 -22 26 -85 146 -79 152 21 20
459 267 472 265 8 -1 32 -32 53 -69z m4496 -444 c36 -31 67 -60 69 -64 1 -4
-28 -42 -65 -86 -38 -43 -127 -148 -198 -233 -72 -85 -163 -193 -202 -240 -40
-47 -73 -91 -73 -97 0 -9 121 -117 178 -160 12 -8 5 -21 -38 -73 -28 -34 -55
-61 -60 -59 -16 6 -340 283 -340 291 0 8 65 87 398 480 56 67 135 161 175 209
40 48 76 87 81 88 5 0 39 -25 75 -56z m-5159 -85 c74 -60 253 -205 398 -321
l264 -212 -59 -53 c-32 -29 -66 -55 -76 -58 -12 -5 -41 13 -95 58 -42 35 -83
67 -89 71 -12 7 -135 -86 -146 -110 -3 -5 19 -53 49 -105 29 -52 53 -98 54
-104 0 -11 -124 -115 -137 -115 -7 0 -134 236 -420 779 l-73 138 83 71 c45 39
89 71 97 71 8 0 76 -49 150 -110z m2803 -249 c382 -56 629 -131 937 -282 370
-182 687 -426 966 -743 156 -176 316 -416 423 -632 74 -150 129 -288 118 -299
-5 -6 -14 6 -118 150 -179 249 -259 345 -439 528 l-135 136 -175 7 c-354 15
-592 20 -603 13 -8 -5 -6 -23 6 -62 38 -119 86 -327 102 -440 9 -66 19 -125
22 -133 4 -9 28 -13 87 -13 111 0 501 -20 555 -28 65 -10 51 -26 -31 -34 -139
-13 -757 -29 -1383 -36 l-630 -7 -11 -55 c-5 -30 -14 -54 -20 -52 -5 1 -13 26
-19 55 l-10 52 -600 7 c-525 6 -1086 20 -1397 35 -92 4 -119 13 -82 27 17 6
246 21 524 34 l155 7 11 60 c7 33 18 98 24 145 14 91 55 267 88 367 14 46 17
65 8 71 -10 9 -171 5 -616 -13 l-165 -7 -145 -146 c-153 -156 -358 -403 -436
-527 -38 -61 -114 -158 -114 -146 0 57 173 422 266 561 167 251 242 343 441
545 367 373 880 665 1378 785 88 21 196 43 240 49 44 6 105 15 135 20 79 12
558 13 643 1z m2923 -222 c119 -129 166 -221 166 -328 -1 -115 -46 -202 -164
-316 -176 -170 -361 -251 -510 -224 -80 15 -129 41 -193 101 -51 48 -165 198
-165 215 1 5 29 30 63 56 34 26 73 56 87 67 14 11 90 71 169 133 79 62 217
170 305 240 89 70 164 127 168 127 3 1 36 -32 74 -71z m-6228 -61 c150 -53
367 -240 432 -372 32 -64 35 -77 35 -161 0 -84 -3 -95 -37 -165 -41 -82 -162
-232 -183 -227 -8 2 -110 77 -226 168 -117 91 -245 190 -284 220 -225 173
-275 213 -275 222 0 16 31 70 76 134 71 100 154 169 234 195 38 13 179 4 228
-14z m1689 -2017 c8 -14 49 -101 92 -195 68 -148 410 -883 516 -1110 20 -44
48 -104 61 -132 l24 -53 -128 0 -127 0 -100 218 c-54 119 -104 220 -110 224
-5 4 -219 7 -473 5 l-464 -2 -102 -220 c-92 -198 -105 -220 -127 -220 -18 0
-23 5 -21 18 3 17 54 132 154 347 20 41 65 138 101 215 36 77 84 181 107 230
23 50 50 108 60 130 107 233 248 535 258 553 13 22 17 23 140 20 123 -3 127
-4 139 -28z m1876 -2 c36 -17 81 -51 117 -87 77 -79 103 -145 104 -262 1 -81
-1 -90 -38 -163 -25 -49 -56 -92 -84 -117 -25 -21 -43 -41 -41 -42 2 -2 29
-11 59 -20 77 -23 141 -66 191 -129 54 -68 75 -117 90 -205 29 -174 -62 -343
-229 -425 l-67 -33 -592 -3 -593 -3 0 761 0 761 513 -3 512 -3 58 -27z m787
-398 c0 -236 4 -430 9 -430 9 0 44 37 167 175 39 44 154 173 255 286 101 113
222 249 269 302 80 90 87 97 123 97 l38 0 -28 -34 c-15 -18 -142 -160 -282
-316 l-253 -282 29 -31 c16 -18 85 -95 154 -172 68 -77 140 -158 161 -180 20
-22 45 -49 55 -61 10 -12 52 -59 93 -105 133 -148 263 -293 284 -316 l19 -23
-154 0 -154 0 -151 168 c-83 92 -211 235 -284 317 -191 213 -203 226 -210 223
-4 -2 -37 -36 -73 -77 l-67 -75 0 -278 0 -278 -115 0 -115 0 0 760 0 760 115
0 115 0 0 -430z m-4940 104 c7 -7 8 -28 4 -52 l-6 -41 -58 6 c-50 5 -60 3 -79
-16 -42 -42 -28 -77 55 -137 36 -25 76 -63 91 -84 22 -35 25 -47 21 -109 -3
-64 -7 -74 -38 -106 -49 -51 -105 -69 -187 -62 -37 4 -70 10 -75 15 -14 14 7
97 23 93 82 -21 155 -6 165 35 11 43 -5 71 -73 125 -90 72 -113 108 -113 173
1 111 79 181 197 174 35 -2 68 -9 73 -14z m-352 -41 l-3 -48 -72 -3 -73 -3 0
-69 0 -70 70 0 70 0 0 -50 0 -50 -70 0 -70 0 0 -80 0 -79 78 -3 77 -3 3 -47 3
-48 -135 0 -136 0 0 300 0 300 131 0 130 0 -3 -47z m754 35 c3 -7 4 -29 3 -48
-1 -35 -1 -35 -53 -38 l-52 -3 0 -250 0 -249 -60 0 -60 0 -2 248 -3 247 -52 3
-53 3 0 50 0 49 164 0 c122 0 165 -3 168 -12z m6106 -12 c59 -31 76 -61 80
-141 4 -66 1 -81 -26 -137 -16 -35 -53 -91 -81 -126 -28 -34 -51 -64 -51 -67
0 -3 38 -5 85 -5 l85 0 0 -50 0 -50 -160 0 -160 0 0 26 c0 36 23 81 70 134 62
71 122 184 128 240 4 43 1 52 -23 75 -27 28 -53 30 -122 9 -24 -7 -24 -7 -27
64 -2 36 146 57 202 28z m401 -2 c100 -59 132 -334 57 -489 -31 -62 -63 -86
-122 -93 -110 -12 -175 95 -174 288 0 156 33 252 99 294 33 20 105 20 140 0z
m371 -2 c19 -9 45 -35 58 -57 48 -84 14 -209 -94 -343 l-53 -67 89 -3 90 -3 0
-49 0 -50 -160 0 -160 0 0 40 c0 35 9 52 65 123 86 108 125 186 125 248 0 79
-47 107 -125 75 -37 -16 -35 -18 -49 45 -5 23 -1 29 27 43 44 21 142 21 187
-2z m440 -162 l5 -175 28 -3 c25 -3 27 -7 27 -47 0 -40 -2 -44 -27 -47 -27 -3
-28 -5 -31 -70 l-3 -68 -49 0 -49 0 -3 68 -3 67 -97 3 -98 3 0 43 c0 33 12 65
51 137 28 52 69 130 91 174 22 44 46 83 52 88 6 4 31 6 56 5 l45 -3 5 -175z
m-6828 -1340 c27 -41 61 -88 73 -105 13 -16 45 -59 71 -95 95 -128 398 -455
449 -483 12 -6 113 -14 240 -19 121 -4 291 -10 377 -14 141 -6 158 -5 164 10
3 9 -12 80 -35 159 -22 78 -50 201 -61 272 -12 72 -24 148 -28 170 l-7 40
-175 7 c-411 16 -579 32 -514 51 132 38 2295 62 3389 37 657 -15 886 -29 846
-55 -17 -10 -221 -23 -474 -31 -224 -8 -227 -9 -227 -89 0 -82 -51 -325 -102
-483 -11 -34 -17 -69 -14 -77 7 -18 92 -19 296 -6 74 5 212 13 305 16 94 4
177 11 186 16 23 12 294 294 341 354 113 147 201 263 267 355 41 57 76 99 78
93 7 -19 -49 -159 -121 -303 -90 -179 -186 -334 -308 -495 -79 -104 -271 -311
-356 -385 -35 -30 -85 -74 -110 -97 -26 -23 -50 -42 -54 -43 -4 0 -13 -6 -20
-13 -113 -108 -479 -323 -723 -425 -139 -59 -358 -124 -540 -161 -246 -50
-667 -63 -900 -28 -374 57 -616 132 -927 287 -237 119 -398 226 -618 411 -106
89 -141 124 -301 294 -77 83 -241 303 -316 423 -83 133 -197 362 -228 457 -14
43 -25 80 -25 83 0 11 53 -56 102 -128z m-116 -481 c58 -79 121 -209 129 -266
16 -117 -43 -241 -159 -334 -84 -68 -263 -156 -356 -175 -192 -40 -332 40
-444 256 l-49 94 23 15 c58 38 796 460 807 460 6 1 28 -22 49 -50z m5849 -44
c126 -32 288 -126 377 -218 120 -122 151 -247 97 -393 -20 -54 -114 -214 -131
-225 -6 -4 -94 42 -197 101 -598 346 -636 369 -633 387 6 33 94 198 129 241
87 107 213 144 358 107z m-5275 -741 c61 -62 72 -79 63 -92 -5 -9 -87 -125
-181 -257 -94 -132 -201 -283 -239 -335 -37 -52 -85 -119 -106 -149 l-39 -53
-59 58 c-33 32 -59 63 -59 69 0 6 27 48 60 93 33 45 60 85 60 90 0 4 -26 36
-57 70 l-58 63 -96 -59 c-108 -67 -93 -69 -178 20 l-43 46 43 28 c43 27 360
220 649 395 80 49 150 88 156 89 6 0 44 -34 84 -76z m4593 -71 c56 -54 142
-136 192 -183 49 -47 130 -124 178 -170 48 -47 94 -88 102 -92 9 -6 37 18 91
77 43 47 83 85 89 85 19 -1 115 -95 107 -106 -24 -38 -288 -304 -298 -302 -6
2 -92 80 -190 173 -98 94 -226 215 -284 269 -58 55 -133 127 -167 160 l-62 61
57 62 c31 34 63 62 70 63 7 0 59 -44 115 -97z m-4203 -268 c58 -40 150 -102
205 -140 55 -37 102 -69 104 -71 4 -3 -80 -126 -93 -136 -5 -3 -36 14 -70 38
-33 24 -65 44 -71 44 -6 0 -40 -42 -75 -92 -195 -285 -373 -543 -381 -552 -12
-13 -151 85 -146 104 2 8 100 154 217 324 117 170 213 316 214 324 1 8 -23 32
-52 52 -80 56 -78 52 -42 107 47 69 61 83 74 76 6 -3 58 -38 116 -78z m3809
-44 c48 -25 108 -91 132 -146 30 -69 34 -122 12 -187 l-20 -58 27 0 c51 0 129
-54 249 -173 l120 -117 -75 -51 c-51 -34 -81 -48 -94 -44 -10 3 -64 55 -119
114 -124 134 -164 153 -225 108 -17 -12 -12 -23 83 -168 55 -85 102 -158 103
-164 3 -8 -127 -103 -144 -104 -3 -1 -22 23 -41 52 -358 541 -487 739 -487
747 0 24 187 155 275 192 56 24 156 23 204 -1z m-3130 -328 l93 -37 -6 -50
c-4 -28 -14 -82 -22 -121 -8 -38 -46 -234 -84 -434 -38 -200 -72 -366 -74
-369 -4 -3 -153 54 -164 63 -1 1 7 46 18 100 11 53 20 103 20 110 0 12 -134
75 -158 75 -5 0 -33 -38 -62 -86 -30 -47 -57 -87 -62 -90 -10 -6 -156 44 -163
56 -2 4 72 119 165 256 93 137 217 320 275 407 59 86 112 157 118 157 7 0 54
-16 106 -37z m2314 -45 c25 -8 65 -34 101 -69 74 -69 131 -170 182 -325 31
-92 37 -127 41 -224 4 -100 2 -122 -16 -170 -50 -134 -194 -206 -327 -164
-134 42 -265 235 -320 471 -22 96 -16 271 13 334 56 125 193 187 326 147z
m-653 -192 c0 -13 -22 -158 -49 -322 -27 -165 -62 -374 -77 -466 l-27 -168
-97 0 -98 0 -35 188 c-20 103 -43 237 -53 297 -9 61 -18 117 -21 125 -3 8 -16
-52 -29 -135 -13 -82 -39 -224 -56 -315 l-33 -165 -92 -3 -91 -3 -11 53 c-10
49 -62 333 -112 603 -11 61 -29 155 -40 209 -10 55 -15 104 -11 108 5 5 49 8
98 6 l89 -3 7 -50 c24 -167 49 -361 64 -502 10 -89 21 -160 26 -157 4 3 8 18
8 34 0 15 20 149 44 296 25 148 48 294 53 324 l8 55 77 3 c74 3 78 2 83 -20 3
-13 16 -86 30 -163 13 -77 33 -189 44 -250 11 -60 23 -139 27 -175 12 -112 20
-108 33 16 34 327 62 580 66 592 3 8 30 12 90 12 83 0 85 -1 85 -24z"/>
					<path d="M7499 10367 c-78 -52 -188 -353 -189 -520 0 -75 19 -120 58 -136 43
-18 84 8 127 80 69 113 141 379 133 492 -4 50 -9 64 -32 84 -34 29 -54 29 -97
0z"/>
					<path d="M5110 10269 c0 -29 63 -404 70 -412 8 -9 130 32 130 45 0 8 -186 366
-196 377 -2 2 -4 -2 -4 -10z"/>
					<path d="M8300 9988 c-24 -46 -57 -105 -73 -132 l-29 -50 24 -18 c31 -24 104
-23 145 1 35 22 77 88 88 137 9 45 -16 97 -58 122 -19 12 -39 22 -44 22 -5 0
-29 -37 -53 -82z"/>
					<path d="M3830 9565 c0 -5 127 -221 193 -327 16 -27 32 -48 37 -48 13 0 100
75 100 86 0 7 -62 66 -137 132 -152 133 -193 166 -193 157z"/>
					<path d="M6108 9076 c-176 -60 -312 -159 -488 -352 -45 -50 -127 -160 -146
-195 -21 -41 -4 -47 111 -37 55 4 154 13 220 18 66 6 202 10 302 10 153 0 183
2 191 15 4 9 1 92 -10 198 -10 100 -21 220 -24 267 -7 88 -15 110 -43 110 -9
0 -60 -15 -113 -34z"/>
					<path d="M6600 9091 c-5 -11 -10 -43 -10 -71 0 -28 -5 -81 -10 -118 -6 -37
-14 -121 -20 -187 -5 -66 -11 -129 -15 -140 -13 -47 -1 -50 247 -56 128 -4
311 -12 407 -19 95 -6 177 -8 182 -3 9 9 -38 84 -107 175 -60 77 -189 205
-264 262 -81 61 -235 140 -316 160 -78 20 -81 20 -94 -3z"/>
					<path d="M5505 8985 c-149 -39 -453 -182 -645 -302 -129 -81 -176 -114 -295
-208 -165 -130 -169 -140 -43 -118 327 55 377 63 553 84 55 7 108 16 117 21
10 4 40 52 68 106 58 111 152 247 240 345 34 37 60 71 58 76 -2 5 -25 3 -53
-4z"/>
					<path d="M7281 8992 c2 -6 29 -37 59 -69 109 -118 201 -248 266 -378 22 -44
48 -81 59 -85 11 -5 65 -13 120 -20 95 -11 378 -55 550 -85 44 -7 83 -10 88
-6 8 8 -109 111 -222 194 -128 94 -350 228 -496 298 -214 102 -435 181 -424
151z"/>
					<path d="M5598 8330 c-135 -3 -249 -7 -251 -10 -32 -33 -287 -600 -287 -639
l0 -24 308 7 c169 3 461 6 649 7 376 0 381 1 368 58 -3 14 -10 80 -15 146 -5
66 -14 165 -20 220 -6 55 -13 127 -17 160 -3 33 -10 66 -16 73 -10 13 -204 13
-719 2z"/>
					<path d="M6534 8329 c-11 -14 -18 -62 -33 -244 -5 -55 -15 -166 -22 -247 -7
-81 -10 -150 -6 -154 3 -3 158 -8 344 -9 186 -2 481 -7 656 -10 368 -8 338
-20 284 110 -131 311 -237 529 -265 544 -9 5 -223 12 -482 17 -392 6 -467 5
-476 -7z"/>
					<path d="M9450 8914 c-80 -64 -200 -158 -267 -210 -67 -52 -125 -99 -128 -105
-4 -5 10 -26 31 -46 37 -35 39 -35 108 -31 81 5 123 24 236 103 146 103 240
219 240 298 0 37 -37 101 -60 104 -8 1 -80 -50 -160 -113z"/>
					<path d="M3226 8980 c-31 -10 -86 -57 -86 -75 0 -6 66 -62 147 -125 218 -171
355 -278 385 -300 l26 -20 26 31 c58 68 21 177 -98 293 -145 142 -311 223
-400 196z"/>
					<path d="M4797 6883 c-53 -114 -150 -324 -216 -467 -66 -142 -118 -263 -115
-267 6 -10 857 -13 866 -4 7 7 -412 919 -431 938 -4 4 -51 -86 -104 -200z"/>
					<path d="M6167 7103 c-4 -3 -7 -150 -7 -325 l0 -319 308 3 c299 3 308 4 350
26 63 34 124 93 152 149 28 56 39 173 21 239 -17 62 -69 132 -126 171 -86 57
-127 63 -426 63 -146 0 -269 -3 -272 -7z"/>
					<path d="M6165 6388 c-3 -7 -4 -166 -3 -353 l3 -340 340 -3 c355 -3 413 1 488
40 125 63 208 213 192 348 -14 118 -97 235 -204 287 l-56 28 -378 3 c-303 2
-378 0 -382 -10z"/>
					<path d="M9663 6703 c-27 -41 -26 -306 0 -347 26 -39 56 -32 74 19 18 50 14
298 -5 333 -17 30 -48 28 -69 -5z"/>
					<path d="M10430 6584 c-25 -53 -46 -100 -48 -105 -2 -5 19 -9 47 -9 l51 0 0
105 c0 58 -1 105 -3 105 -1 0 -23 -43 -47 -96z"/>
					<path d="M5064 5236 c-4 -9 3 -40 14 -69 110 -276 250 -570 278 -581 10 -4
231 -10 491 -13 l472 -6 10 25 c5 14 14 75 20 134 13 135 29 292 40 404 5 47
7 88 5 91 -2 3 -225 9 -496 12 -271 4 -568 10 -660 14 -152 5 -168 4 -174 -11z"/>
					<path d="M7320 5239 c-157 -3 -415 -7 -574 -8 l-289 -1 6 -87 c12 -196 52
-546 64 -566 7 -11 935 -3 966 9 11 4 47 65 88 150 38 79 69 146 69 148 0 3
16 38 35 78 54 116 107 258 101 274 -4 11 -25 14 -93 12 -48 -1 -216 -5 -373
-9z"/>
					<path d="M4426 4562 c-7 -11 36 -52 149 -139 87 -68 250 -183 259 -183 2 0 41
-22 85 -50 148 -92 379 -200 546 -255 129 -42 131 -39 43 53 -102 108 -198
243 -261 368 -46 91 -59 104 -107 104 -22 0 -61 4 -87 9 -26 5 -88 15 -138 21
-49 6 -137 20 -195 30 -58 10 -139 23 -180 30 -41 6 -82 14 -92 16 -9 3 -19 1
-22 -4z"/>
					<path d="M8310 4549 c-30 -5 -109 -19 -175 -29 -66 -11 -149 -25 -185 -30 -36
-5 -98 -13 -138 -19 -40 -6 -85 -11 -100 -11 -43 0 -69 -25 -113 -108 -70
-136 -155 -254 -264 -372 -68 -73 -63 -81 30 -51 277 89 699 318 910 492 11 9
48 40 83 68 34 29 62 56 62 61 0 12 -34 11 -110 -1z"/>
					<path d="M5467 4414 c-32 -32 248 -353 401 -460 103 -72 293 -154 359 -154 27
0 27 0 47 200 9 85 21 198 28 251 6 53 8 103 5 111 -4 12 -63 17 -310 27 -167
7 -333 16 -368 21 -74 11 -153 13 -162 4z"/>
					<path d="M7200 4409 c-102 -10 -461 -29 -566 -29 -68 0 -86 -3 -91 -16 -3 -9
-1 -53 5 -98 6 -44 16 -133 22 -196 20 -195 28 -252 39 -263 17 -17 132 17
246 74 104 51 234 148 316 235 105 113 224 278 213 295 -6 10 -77 10 -184 -2z"/>
					<path d="M3271 4490 c-151 -87 -278 -163 -283 -168 -13 -13 8 -53 39 -76 40
-30 110 -32 190 -6 181 58 358 187 383 279 10 37 9 51 -5 87 -11 29 -23 44
-33 43 -9 0 -140 -72 -291 -159z"/>
					<path d="M9284 4606 c-35 -16 -76 -63 -72 -84 2 -8 62 -48 133 -89 72 -41 199
-115 284 -165 85 -49 160 -87 167 -84 7 3 18 23 25 45 24 81 -37 174 -174 265
-90 59 -156 91 -232 112 -62 17 -91 17 -131 0z"/>
					<path d="M4000 3884 c-25 -18 -98 -69 -162 -111 -65 -42 -118 -82 -118 -88 0
-19 89 -100 98 -90 5 6 31 39 58 75 27 36 51 67 54 70 3 3 20 25 39 50 19 25
50 64 69 88 42 51 25 54 -38 6z"/>
					<path d="M8186 3489 c-15 -12 -25 -27 -22 -34 5 -13 134 -215 146 -228 12 -14
49 11 70 46 29 47 26 104 -9 159 -16 25 -39 53 -52 62 -34 23 -100 21 -133 -5z"/>
					<path d="M5158 3108 c-19 -29 -54 -87 -79 -128 -24 -41 -59 -97 -77 -125 l-32
-50 61 -28 c35 -15 66 -24 69 -20 4 4 20 62 35 128 15 66 35 154 46 195 10 41
16 76 14 78 -2 2 -19 -20 -37 -50z"/>
					<path d="M7463 3127 c-58 -26 -57 -167 1 -341 74 -222 155 -322 231 -288 58
27 55 156 -10 348 -43 130 -84 210 -127 250 -38 36 -65 45 -95 31z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
